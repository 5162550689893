import React, { useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormHelperText,
  Tabs,
  useMediaQuery,
  Tab,
} from '@mui/material';
import {
  UserRole,
  UsersEditUserForm,
  disableUser,
  enableUser,
  getGetByIdQueryKey,
  resetPassword,
  useEditUser,
  useGetById,
} from '../../api/agency-api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, TextInput } from '../../shared/forms';
import { useLocation } from 'react-router-dom';
import { PhoneNumberMask } from '../../shared/forms/fields/InputMasks';
import { EnqueueSnackbar, useSnackbar } from 'notistack';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useQueryClient } from '@tanstack/react-query';
import { DashboardBanner, Link } from '../../shared/components';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../shared/icons';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      sx={{ width: ['100%', '100%', '80%'] }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export const EditUserPage: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  let location = useLocation();
  const { data } = useGetById(location.pathname.split('/')[3]);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const edit = useEditUser({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('User updated successfully', { variant: 'success' });
        queryClient.invalidateQueries({ queryKey: getGetByIdQueryKey(data?.user?.id ?? '') });
      },
      onError: (error: any) => {
        if (error.response.data.detail) {
          enqueueSnackbar(error.response.data.detail, { variant: 'error' });
        } else {
          enqueueSnackbar('Error updating user', { variant: 'error' });
        }
      },
    },
  });
  return data?.user ? (
    <Form<UsersEditUserForm>
      onSubmit={form => edit.mutate({ id: form.id, data: form })}
      defaultValues={{
        email: data?.user?.email,
        phoneNumber: data?.user?.phoneNumber,
        name: { first: data?.user?.name.first, last: data?.user?.name.last },
        id: data?.user?.id,
        address: {
          line1: data?.user?.address.line1,
          line2: data?.user?.address.line2,
          city: data?.user?.address.city,
          state: data?.user?.address.state,
          zipcode: data?.user?.address.zipcode,
        },
        userRole: data?.user?.role,
      }}
      resolver={yupResolver(schema)}
      method="put"
    >
      <Box>
        <DashboardBanner
          bannerBGColor="primary.light"
          align="end"
          justify="space-between"
          padding={2}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            width="100%"
            justifyContent="space-between"
            alignItems={{ xs: 'start', md: 'center' }}
          >
            <Stack>
              <Link
                to={'/admin/dashboard'}
                sx={{ fontSize: '.75rem', color: 'common.white', fontWeight: '400' }}
              >
                <FontAwesomeIcon icon={icons.back} /> Back to dashboard
              </Link>
              <Typography variant="h5" component="h1" sx={{ color: 'common.white' }}>
                {data?.user?.name.first} {data?.user?.name.last}
              </Typography>
            </Stack>

            <Button type="submit" variant="outlined">
              <Typography variant="body2">Update</Typography>
            </Button>
          </Stack>
        </DashboardBanner>

        <Stack direction={{ xs: 'column', md: 'row' }} sx={{ width: '100%' }}>
          <Tabs
            orientation={isSmallScreen ? 'horizontal' : 'vertical'}
            variant="scrollable"
            scrollButtons
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab
              label="User Information"
              {...a11yProps(0)}
              sx={{ alignItems: 'start', '&.Mui-selected': { backgroundColor: 'grey.200' } }}
            />
            <Tab
              label="Security"
              {...a11yProps(1)}
              sx={{ alignItems: 'start', '&.Mui-selected': { backgroundColor: 'grey.200' } }}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2" color="primary.main">
                  General
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInput name="name.first" variant="outlined" label="First Name" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInput name="name.last" variant="outlined" label="Last Name" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInput name="email" variant="outlined" label="Email" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInput
                  InputProps={{ inputComponent: PhoneNumberMask as any }}
                  name="phoneNumber"
                  variant="outlined"
                  label="Phone Number (optional)"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInput name="address.line1" variant="outlined" label="Address Line 1" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInput
                  name="address.line2"
                  variant="outlined"
                  label="Addres Line 2 (optional)"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInput name="address.city" variant="outlined" label="City" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInput name="address.state" variant="outlined" label="State" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInput name="address.zipcode" variant="outlined" label="Zipcode" />
              </Grid>
              {/* <TextInput name="id" variant="outlined" label="ID" /> */}
            </Grid>

            <Typography variant="h5" component="h2" color="primary.main">
              Roles
            </Typography>
            <FormControl>
              <Controller
                rules={{ required: true }}
                name="userRole"
                render={({ field }) => (
                  <>
                    <RadioGroup {...field}>
                      {Object.values(UserRole).map(x => (
                        <FormControlLabel
                          control={<Radio />}
                          label={x}
                          value={x}
                          sx={{
                            marginBottom: '.5rem',
                            width: { xs: '100%', xl: '32%' },
                            '& .MuiTypography-body1': { fontSize: '1rem', color: 'common.black' },
                          }}
                        />
                      ))}
                    </RadioGroup>
                    <ErrorMessage
                      name="userRole"
                      render={data => <FormHelperText error>{data.message}</FormHelperText>}
                    />
                  </>
                )}
              />
            </FormControl>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography variant="h5" component="h2" color="primary.main">
              Security
            </Typography>
            <FormGroup sx={{ display: 'flex', flexDirection: 'column' }}>
              <Button
                onClick={() => resetPasswordSubmit(enqueueSnackbar, data.user?.id)}
                variant="contained"
                sx={{ marginBottom: '.5rem' }}
              >
                Email Password Reset Link
              </Button>
              <Button
                onClick={() => disableUserSubmit(enqueueSnackbar, data.user?.id)}
                variant="contained"
                sx={{ marginBottom: '.5rem' }}
              >
                Disable User
              </Button>
              <Button
                onClick={() => enableUserSubmit(enqueueSnackbar, data.user?.id)}
                variant="contained"
                sx={{ marginBottom: '.5rem' }}
              >
                Enable User
              </Button>
            </FormGroup>
          </TabPanel>
        </Stack>
      </Box>
    </Form>
  ) : null;
};

const resetPasswordSubmit = (enqueueSnackbar: EnqueueSnackbar, id?: string) => {
  if (!id) return;
  resetPassword(id)
    .then(() => {
      enqueueSnackbar('Password reset successfully', { variant: 'success' });
    })
    .catch(() => {
      enqueueSnackbar('Password reset failed', { variant: 'error' });
    });
};

const disableUserSubmit = (enqueueSnackbar: EnqueueSnackbar, id?: string) => {
  if (!id) return;
  disableUser(id)
    .then(() => {
      enqueueSnackbar('User disabled successfully', { variant: 'success' });
    })
    .catch(() => {
      enqueueSnackbar('User disable failed', { variant: 'error' });
    });
};

const enableUserSubmit = (enqueueSnackbar: EnqueueSnackbar, id?: string) => {
  if (!id) return;
  enableUser(id)
    .then(() => {
      enqueueSnackbar('User enabled successfully', { variant: 'success' });
    })
    .catch(() => {
      enqueueSnackbar('User enable failed', { variant: 'error' });
    });
};

const schema = yup.object({
  email: yup.string().label('Email').email().required(),
  phoneNumber: yup.string().label('Phone Number').nullable(),
  name: yup.object({
    first: yup.string().label('First Name').required(),
    last: yup.string().label('Last Name').required(),
  }),
  id: yup.string().uuid().label('ID').required(),
  address: yup.object({
    city: yup.string().label('City').required(),
    state: yup.string().label('State').required(),
    line1: yup.string().label('Address Line 1').required(),
    line2: yup.string().label('Address Line 2').nullable(),
    zipcode: yup.string().label('Zipcode').required(),
  }),
  userRole: yup
    .mixed<UserRole>()
    .oneOf(Object.values(UserRole))
    .required('User Role is a required field'),
});
