import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import { TextInput } from '../../shared/forms';
import { DropDownItem, DropDownSelect } from '../../shared/forms/fields/DropDownSelect';
import { TaxIdMask } from '../../shared/forms/fields/InputMasks';
import { CheckboxInput } from '../../shared/forms/fields/CheckboxInput';
import { useCallback } from 'react';

interface AgencyInfoProps {
  countiesServed: string[];
  categories: DropDownItem[];
  updateCountiesServed(value: React.SetStateAction<string[]>): void;
}

export default function AgencyInfo({
  countiesServed,
  categories,
  updateCountiesServed,
}: AgencyInfoProps) {
  const counties = [
    'Genesee',
    'Livingston',
    'Monroe',
    'Ontario',
    'Orleans',
    'Seneca',
    'Wayne',
    'Wyoming',
    'Yates',
  ];
  const handleCountyChange = useCallback(
    (value: string) => {
      let selection = new Set(countiesServed);
      if (selection.has(value)) {
        selection.delete(value);
      } else {
        selection.add(value);
      }
      updateCountiesServed(Array.from(selection));
    },
    [countiesServed, updateCountiesServed]
  );
  const isCountySelected = useCallback((county: string, selected: string[]) => {
    let select = new Set(selected);
    if (select.has(county)) {
      return true;
    } else {
      return false;
    }
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" component="h2" color="primary.main">
          Agency Details
        </Typography>
        <Typography>Counties Served</Typography>
        <Typography variant="body2" mb={3}>
          Choose at least one county and select all that apply.
        </Typography>
        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
          {counties.map(item => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCountySelected(item, countiesServed)}
                  onChange={() => handleCountyChange(item)}
                />
              }
              key={`county-${item}`}
              label={item}
              sx={{
                marginBottom: '.5rem',
                width: { xs: '100%', xl: '32%' },
                '& .MuiTypography-body1': { fontSize: '1rem', color: 'common.black' },
              }}
            />
          ))}
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl>
          <DropDownSelect
            name="primaryCategory"
            options={categories}
            label="Primary Category"
            getOptionKey={opt => opt.id}
            getOptionLabel={opt => opt.item}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl>
          <DropDownSelect
            name="secondaryCategory"
            label="Secondary Category (Optional)"
            options={categories}
            getOptionKey={opt => opt.id}
            getOptionLabel={opt => opt.item}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          id="legalName"
          name="legalName"
          variant="outlined"
          label="Legal Name"
          helperText="Legal name of not-for-profit"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          id="taxId"
          name="taxId"
          variant="outlined"
          label="Federal Tax ID# (EIN)"
          helperText="9 digit number with a dash"
          InputProps={{ inputComponent: TaxIdMask as any }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          id="displayName"
          name="displayName"
          variant="outlined"
          label="Display Name"
          helperText="How would you like your agency/program to be listed on the website?"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          id="vanityUrl"
          name="vanityUrl"
          variant="outlined"
          label="Vanity URL"
          helperText="Customize your project/agency website for ROC the Day (ex. ORGANIZATIONNAME)"
          InputProps={{
            startAdornment: <InputAdornment position="start">roctheday.org/</InputAdornment>,
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        py={2}
        sx={{
          borderTop: 'solid thin',
          borderBottom: 'solid thin',
          borderColor: 'grey.200',
          margin: '1rem auto',
        }}
      >
        <FormControlLabel
          control={<CheckboxInput name="isDonor" />}
          label="Check here if this organization is currently a United Way Donor Choice and/or Community Fund-Supported agency. (Optional)"
          sx={{
            '& .MuiTypography-body1': { fontSize: '1rem', color: 'common.black' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" component="p" color="primary.main">
          Displayed Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>Search results description</Typography>
        <TextInput
          name="information"
          multiline
          aria-label="Search Results Description"
          placeholder="Search Results Description"
        />

        <Typography variant="body2" mb={3}>
          This short description is what will be shown in search results (max 350 characters).
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>Organization Description</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="description"
          multiline
          aria-label="Organization Description"
          placeholder="Organization Description"
        />
        <Typography variant="body2" mb={3}>
          This long description is what will be shown in your full agency profile.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="websiteUrl"
          name="websiteUrl"
          variant="outlined"
          label="Organization Website URL"
          helperText="Include the http:// or https:// part of your website url."
        />
      </Grid>
      <Grid
        item
        xs={12}
        py={2}
        sx={{
          borderTop: 'solid thin',
          borderBottom: 'solid thin',
          borderColor: 'grey.200',
          margin: '1rem auto',
        }}
      >
        <FormControlLabel
          control={<CheckboxInput name="isFederatedFund" />}
          name="isFederatedFund"
          label="Check here if this organization operates a 'federated' fund (Optional)"
          sx={{
            '& .MuiTypography-body1': { fontSize: '1rem', color: 'common.black' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<CheckboxInput name="matchingGrant.isEnabled" />}
          label="Our organization is going to secure/promote a matching challenge grant. (Optional)"
          sx={{
            '& .MuiTypography-body1': { fontSize: '1rem', color: 'common.black' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="matchingGrant.amount"
          name="matchingGrant.amount"
          variant="outlined"
          label="Matching Grant Amount"
          sx={{ marginTop: '2rem' }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="matchingGrant.title"
          name="matchingGrant.title"
          variant="outlined"
          label="Matching Grant Title"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="matchingGrant.description"
          aria-label="Matching Grant Description"
          placeholder="Matching Grant Description"
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" component="p" color="primary.main">
          Mailing Address
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          id="mailingAddress.line1"
          name="mailingAddress.line1"
          variant="outlined"
          label="Address Line 1"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          id="mailingAddress.line2"
          name="mailingAddress.line2"
          variant="outlined"
          label="Address Line 2"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextInput
          id="mailingAddress.city"
          name="mailingAddress.city"
          variant="outlined"
          label="Address City"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextInput
          id="mailingAddress.state"
          name="mailingAddress.state"
          variant="outlined"
          label="Address State"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextInput
          id="mailingAddress.zipcode"
          name="mailingAddress.zipcode"
          variant="outlined"
          label="Address ZIP"
        />
      </Grid>
    </Grid>
  );
}
